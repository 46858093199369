import React from "react";
import {useNavigate} from 'react-router-dom';
import imgFacebook from './immagini/facebook.png';
import imgInstagram from './immagini/instagram.png';

function FondoPagina(props) {    
    const comandoNavigazione = useNavigate();    

    function vaiLink(link) {
        comandoNavigazione(link);
        window.scrollTo(0, 0);
    }
    
    return (
        <>
            <div style={{minHeight:40, color:"#909090", backgroundColor:"#F5F5F5", marginLeft:20, marginRight:20, paddingTop:20}}>

                <div style={{display:"flex", flexWrap: "wrap", justifyContent: "center"}}>
                    {props.pagina!==undefined && props.pagina==="principale" ? <></> :
                    <>
                        <div onClick={()=>vaiLink("/")} style={{color:"#4040FF", cursor:"pointer", whiteSpace: "nowrap", paddingLeft:20, paddingRight:20}}>Torna alla home →</div>
                        {props.dimensioniApp.flagflagPaginaOrizzontale ? "" : <><br/><br/></>}
                    </>
                    }
                    
                    <div style={{color:"#FF4040", cursor:"pointer", whiteSpace: "nowrap", paddingLeft:20, paddingRight:20}} onClick={() => vaiLink("/provaGratis")} ><b>Prova gratis →</b></div>
                    {props.pagina!=="scaricaApp" && <span style={{color:"#4040FF", cursor:"pointer", whiteSpace: "nowrap", paddingLeft:20, paddingRight:20}} onClick={() => vaiLink("/scaricaApp")} >APP Iscritti→</span>}
                    <div style={{color:"#4040FF", cursor:"pointer", whiteSpace: "nowrap", paddingLeft:20, paddingRight:20}} onClick={() => vaiLink("/acquista")}>Acquista →</div>
                    <div style={{color:"#4040FF", cursor:"pointer", whiteSpace: "nowrap", paddingLeft:20, paddingRight:20}}  onClick={() => window.location.href = props.urlServizio}>Accedi →</div>
                </div>
            </div>

            <div style={{backgroundColor:"#F5F5F5", marginLeft:20, marginRight:20, marginBottom:20, paddingTop:20, paddingBottom:20}}>
                <div style={{display:"flex", flexWrap: "wrap", justifyContent: "center"}}>
                    <div style={{cursor:"pointer", whiteSpace: "nowrap", paddingLeft:20, paddingRight:20}} onClick={() => vaiLink("/chiSiamo")}>Chi siamo</div>
                    <div style={{cursor:"pointer", whiteSpace: "nowrap", paddingLeft:20, paddingRight:20}} onClick={() => vaiLink("/privacy")}>Privacy e cookies</div>
                    <div style={{cursor:"pointer", whiteSpace: "nowrap", paddingLeft:20, paddingRight:20}} onClick={() => vaiLink("/condizioni")}>Termini e condizioni</div>
                    <div style={{cursor:"pointer", whiteSpace: "nowrap", paddingLeft:20, paddingRight:20}} onClick={() => vaiLink("/contatti")}>Contattaci</div>
                </div>
                <div style={{display:"flex", flexWrap: "wrap", justifyContent: "center"}}>
                    <div style={{color:"#4040FF", cursor:"pointer", paddingLeft:20, paddingRight:20, marginTop:20}} onClick={() => window.location.href = "https://www.facebook.com/profile.php?id=61559925675497"}><img src={imgFacebook} alt="Facebook"/></div>
                    <div style={{color:"#4040FF", cursor:"pointer", paddingLeft:20, paddingRight:20, marginTop:20}}><img src={imgInstagram} alt="Instagram"/></div>
                </div>
                <div style={{fontSize:9, marginTop:20}}>(c) 2025, {props.produttore.ragioneSociale}, P.IVA {props.produttore.pIva}</div>
            </div>          
      </>
    )
}

export default FondoPagina;