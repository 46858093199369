import React, { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';

import MenuAlto from './menuAlto'
import Intestazione from './intestazione'
import TitoloPagina from './TitoloPagina'
import FondoPagina from './fondoPagina'
import ProvaGratis from './ProvaGratis'
import Acquista from './Acquista'
import Prezzi from './Prezzi'
import ChiSiamo from './chisiamo'
import Clessidra from './Clessidra';
import ScaricaApp from './ScaricaApp'

import Parametri from './parametri';

import imgEventi from './immagini/homeEventi.jpeg';
import imgPrenotazioni from './immagini/homePrenotazioni.png';
import imgAllenamenti from './immagini/homeAllenamenti.png';
import imgGestioneEconomica from './immagini/homePrimaNota.png';
import imgScadenze from './immagini/homeScadenze.png';
import imgNews from './immagini/homeNews.png';
import imgReport from './immagini/homeReport.png';
import imgApp from './immagini/homeApp.png';
import imgAnagrafiche from './immagini/homeAnagrafiche.png';
import imgControlloAccessi from './immagini/homeControlloAccessi.png';
import imgQrcode from './immagini/homeQrcode.png';
import imgImportExport from './immagini/importexport.png';
import logoAIG from './immagini/AIG-piccolo.png';

const packageJson = require('../package.json');

const produttore = {"ragioneSociale":"AIG Azienda Informatica Genova S.r.l.", "pIva":"02927720991"};

function PaginaPrincipale(props) {
    const [dimensioniApp, setDimensioniApp] = useState({x: 0, y:0, flagPaginaOrizzontale:false});
    const altezzaContenitoreHome = 320;
    var larghezzaImmagini = 560;
    const larghezzaMinimaPerPaginaOrizzontale = 800;
    var proporzioneLarghezzaAltezzaImmagini = 2;

    const comandoNavigazione = useNavigate();

    const versioneCorrenteNonAggiornata = (ultimaVersione, versioneCorrente) => {
        const versionsA = ultimaVersione.split(/\./g);
      
        const versionsB = versioneCorrente.split(/\./g);
        while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
      
        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
        }
        return false;
    };

    function ControlloVersione() {        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"versione"})
        };
      
        fetch(Parametri("serverURL"), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {          
                if(valoreDiRitorno.risultatoOperazione===1){
                    //alert("controlloVersione2: a="+a+" back="+valoreDiRitorno.versione+" package="+packageJson.version);
                    if (versioneCorrenteNonAggiornata(valoreDiRitorno.versione, packageJson.version)) {
                        window.location.reload(true);
                    }
                }
            },
            (error) => {
                // se va in error non ricarico altrimenti la fa all'infinito. Potrei indicare errore ma forse è meglio tenersi la pagina non aggiornata...
                //window.location.reload(true);
            }
        ); 
      }

    useEffect(() => {       
        window.scrollTo(0, 0);

        ControlloVersione();
        
        const calcolaDimensioniApp = () => {      
            setDimensioniApp({x:document.documentElement.clientWidth, y:document.documentElement.clientHeight, flagPaginaOrizzontale:document.documentElement.clientWidth>larghezzaMinimaPerPaginaOrizzontale});
        }
        
        calcolaDimensioniApp();
        window.addEventListener('resize', calcolaDimensioniApp);
        
        return () => {
            window.removeEventListener('resize', calcolaDimensioniApp);
        };
    }, []);

    function vaiLink(link) {
        comandoNavigazione(link);
        window.scrollTo(0, 0);
    }    

    function ContenutoImmagineVideo(props) {
        const [flagCaricato, setFlagCaricato] = useState(false);

        var larghezza=larghezzaImmagini;
        if (props.dimensioniApp.flagPaginaOrizzontale) {
            if (larghezza>(dimensioniApp.x-80)/2) {
                larghezza = (dimensioniApp.x-80)/2;
            }
        } else {
            if (larghezza>dimensioniApp.x-120) {
                larghezza = dimensioniApp.x-120;
            }
        }
        var altezza = larghezza / proporzioneLarghezzaAltezzaImmagini;
        if (props.urlContenuto) {    
            if (props.tipoContenuto === "mp4") {
                return (
                    <div style={{minHeight:altezza, minWidth:larghezza, marginLeft:10, marginRight:10}}>
                        <video 
                            style={{height:altezza, opacity: flagCaricato?1:0, transition: "opacity 1s"}} 
                            onLoadedData={() => setFlagCaricato(true)}
                            autoPlay loop muted webkit-playsinline="true" playsinline="true">
                            <source src={props.urlContenuto} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        {!flagCaricato && <Clessidra top={-altezza/1.5} loading={true}/>}
                    </div>
                )    
            }
            if (props.tipoContenuto === "jpg" || props.tipoContenuto === "png") {
                return (
                    <div style={{minHeight:altezza, minWidth:larghezza, marginLeft:10, marginRight:10}}>
                        <img 
                            style={{height:altezza, objectFit:'contain', opacity: flagCaricato?1:0, transition: "opacity 1s"}}                            
                            onLoad={() => setFlagCaricato(true)}
                            src={props.urlContenuto} 
                            type={"image/"+props.tipoContenuto} 
                            alt='immagine'
                        />
                        {!flagCaricato && <Clessidra top={-altezza/1.5} loading={true}/>}
                    </div>
                )    
            }
        }
        if (props.immagine) {
            return (
                <div style={{minHeight:altezza, minWidth:larghezza, marginLeft:10, marginRight:10}}>
                    {props.immagine==="imgEventi" ? <img src={imgEventi} alt="Chat Eventi" height={altezza} /> : <></>}
                    {props.immagine==="imgPrenotazioni" ? <img src={imgPrenotazioni} alt="Prenotazioni" height={altezza} /> : <></>}
                    {props.immagine==="imgAllenamenti" ? <img src={imgAllenamenti} alt="Allenamenti" height={altezza} /> : <></>}
                    {props.immagine==="imgGestioneEconomica" ? <img src={imgGestioneEconomica} alt="Gestione Economica" height={altezza} /> : <></>}
                    {props.immagine==="imgScadenze" ? <img src={imgScadenze} alt="Scadenze" height={altezza} /> : <></>}
                    {props.immagine==="imgNews" ? <img src={imgNews} alt="News" height={altezza} /> : <></>}
                    {props.immagine==="imgReport" ? <img src={imgReport} alt="News" height={altezza} /> : <></>}
                    {props.immagine==="imgApp" ? <img src={imgApp} alt="News" height={altezza} /> : <></>}
                    {props.immagine==="imgAnagrafiche" ? <img src={imgAnagrafiche} alt="News" height={altezza} /> : <></>}
                    {props.immagine==="imgControlloAccessi" ? <img src={imgControlloAccessi} alt="Controllo Accessi" height={altezza} /> : <></>}
                    {props.immagine==="imgImportExport" ? <img src={imgImportExport} alt="Import/Export dati" height={altezza} /> : <></>}
                </div>
            )
        }
        return (<></>)
    }

    function ContenitoreHome(props) {
        const coloriScritte = ["#505050","#F02020"];
        const [coloreScritte,setColoreScritte] = useState(coloriScritte[0]);

        return (
            <div style={{
                minHeight:altezzaContenitoreHome,
                backgroundColor: "#F5F5F5",
                marginTop: 30,
                marginBottom: 40,
                marginLeft: 20,
                marginRight: 20,
                alignItems: "center",
                display:"flex",
                flexDirection: props.dimensioniApp.flagPaginaOrizzontale ? "row" : "column",
                cursor:"pointer",
                fontSize:16,
                lineHeight:1.4                
            }}
                onClick={(e) => props.callback(e.clientY)}
            >
                {(props.posizioneImmagine==="sinistra" && props.dimensioniApp.flagPaginaOrizzontale) ? <ContenutoImmagineVideo {...props}/> : ""}
                
                <div 
                    style={{flex:1, textAlign: "center", marginLeft:10, marginRight:10, marginTop:10, marginBottom:20}}
                    onMouseEnter={() => setColoreScritte(coloriScritte[1])}
                    onMouseLeave={() => setColoreScritte(coloriScritte[0])}
                >
                    <div style={{
                            minHeight:50,
                            fontSize:32,
                            fontWeight:"bold", 
                            color:coloreScritte, 
                            paddingLeft:10, 
                            paddingRight:10
                    }}>
                        {props.titolo}
                    </div>
        
                    {props.dimensioniApp.flagPaginaOrizzontale ? "" : <><div style={{height:20}}/><ContenutoImmagineVideo {...props}/><div style={{height:20}}/></>}

                    <span style={{
                        color:coloreScritte, 
                    }}>
                        {props.descrizione.map((e,i)=><span key={i}>{e}<br/></span>)}
                    </span>
                    <div style={{height:20}}></div>
                    <span style={{color:"#4040FF"}}>Scopri di più →</span>
                </div>

                {(props.posizioneImmagine==="destra" && props.dimensioniApp.flagPaginaOrizzontale) ? <ContenutoImmagineVideo {...props}/> : ""}
            </div>
        )
    }

    function ContenitorePaginaSecondaria(props) {
        const coloriScritte = ["#505050","#F02020"];
        const [coloreScritte,setColoreScritte] = useState(coloriScritte[0]);
        return (
            <div style={{
                minHeight:props.altezza,
                backgroundColor: "#F5F5F5",
                marginTop: 20,
                marginBottom: 20,
                marginLeft: 20,
                marginRight: 20,
                alignItems: "center",
                display:"flex",
                flexDirection: props.dimensioniApp.flagPaginaOrizzontale ? "row" : "column",
                fontSize:16,
                lineHeight:1.4
            }}
            >
                {props.dimensioniApp.flagPaginaOrizzontale ? <ContenutoImmagineVideo {...props}/> : ""}            
                <div 
                    style={{flex:1, textAlign: "center", marginLeft:10, marginRight:10, marginTop:10, marginBottom:20}}
                >
                    <div style={{
                            minHeight:50,
                            fontSize:32,
                            fontWeight:"bold", 
                            color:coloreScritte, 
                            paddingLeft:10, 
                            paddingRight:10
                    }}>
                        {props.titolo}
                    </div>
                    {props.dimensioniApp.flagPaginaOrizzontale ? "" : <><div style={{height:20}}/><ContenutoImmagineVideo {...props}/><div style={{height:20}}/></>}
                    <span style={{
                        color:coloreScritte, 
                    }}>
                        {props.descrizione.map((e,i)=><>{e}<br/></>)}
                    </span>
                </div>
            </div>
        )
    }  

    if (props.pagina==="eventi") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <div style={{lineHeight:2, marginTop:20, marginBottom:60, fontStyle:"italic"}}>
                    Allenamenti di routine o eventi particolari
                    {dimensioniApp.flagPaginaOrizzontale ? ": " : <br/>}
                    gestirli non è mai stato così facile
                </div>

                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Crea" 
                    descrizione={["Crea un nuovo evento, da app o da web.","Un evento può essere un allenamento, una gara, una manifestazione, qualunque tipo di incontro","Vuoi creare un evento tante volte, per esempio un allenamento settimanale? Basta un click per replicarlo quante volte vuoi."]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"EventiCreaNuovo.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Convoca" 
                    descrizione={["Convoca gli atleti, che riceveranno un avviso sulla loro app e indicheranno se saranno presenti con un semplice click nella loro app. Tu vedrai in tempo reale chi ha aderito.","Puoi convocare i singoli atleti o selezionarli per gruppi (es. Under14, Under16, Under18,...)."]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"EventiConvoca.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Scrivi messaggi a tutti" 
                    descrizione={["Invia un messaggio a tutti i convocati in una chat dedicata all'evento, che si crea automaticamente e dove ci sono già tutti i convocati"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"EventiChat1.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Scrivi messaggi a un convocato" 
                    descrizione={["Invia un messaggio a un solo convocato: solo lui potrà vederlo e potrà decidere se rispondere allo staff o a tutti"]}
                    tipoContenuto="mp4"
                    urlContenuto={props.urlMultimedia+"EventiChat2.mp4"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Presenze" 
                    descrizione={["Registra chi è stato presente all'evento"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"EventiPresenze.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Risultati e Report" 
                    descrizione={["Registra i risultati dell'evento", "Ottieni i report delle presenze e dei risultati: su app, su web o generando un pdf stampabile"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"EventiReport.png"}
                    dimensioniApp={dimensioniApp}
                />
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="prenotazioni") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Crea" 
                    descrizione={["Definisci corsi e attrezzature prenotabili.","Imposta, per ciascun corso, gli orari e il numero di posti disponibili"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"PrenotazioniNuovo.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Prenota da app" 
                    descrizione={["Gli iscritti prenotano in autonomia usando la app:","selezionano cosa prenotare, ottengono il calendario con gli orari disponibili e indicazione dei posti rimasti in tempo reale, prenotano e disdicono (se la prenotazione non è troppo vicina nel tempo)"]}
                    tipoContenuto="mp4"
                    urlContenuto={props.urlMultimedia+"PrenotazioniApp.mp4"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Prenota da web" 
                    descrizione={["Inserisci prenotazioni da web selezionando l'iscritto e il corso da prenotare, per gli iscritti che si prenotano telefonicamente o si presentano personalmente"]}
                    tipoContenuto="mp4"
                    urlContenuto={props.urlMultimedia+"PrenotazioniWeb.mp4"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Verifica" 
                    descrizione={["Ottieni il report delle prenotazioni","nella pagina web o in pdf scaricabile e stampabile"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"PrenotazioniReport.png"}
                    dimensioniApp={dimensioniApp}
                />
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="allenamenti") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Inserisci gli esercizi" 
                    descrizione={["Inserisci gli esercizi, da web o da app, se vuoi dividendoli per classificazione (braccia, gambe, addominali,...), con tutte le istruzioni.","Aggiungi un'immagine all'esercizio, anche direttamente dalle foto che hai nel tuo telefono."]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"AllenamentiModificaEsercizio.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Crea e assegna le schede" 
                    descrizione={["Crea le schede, da web o da app, per i diversi tipi di allenamento e poi assegnale ai singoli iscritti, eventualmente personalizzando qualche dato o aggiungendo note specifiche per il singolo atleta"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"AllenamentiModificaScheda.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Scorri una scheda sull'app" 
                    descrizione={["Utilizzando la app, l'atleta visualizza le schede che l'allenatore gli ha assegnato, visualizza gli esercizi uno per schermata, con le istruzioni e le immagini"]}
                    tipoContenuto="mp4"
                    urlContenuto={props.urlMultimedia+"AllenamentiScheda.mp4"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Stampa una scheda" 
                    descrizione={["Genera un PDF con la scheda, scelta tra quelle predefinite o quelle assegnate agli atleti, per inviarlo o stamparlo"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"AllenamentiSchedaPdf.png"}
                    dimensioniApp={dimensioniApp}
                />
                <div style={{marginBottom:20, marginLeft:10, marginRight: 10, fontSize:14}}>
                    Ognuno può caricare le immagini che preferisce, eventualmente prelevandole dalla Galleria del telefono attraverso la App.
                    {dimensioniApp.flagPaginaOrizzontale ? <br/> : <> </>}
                    Le immagini di questa pagina sono <a href="http://www.freepik.com">Designed by grmarc / Freepik</a>
                </div>
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="gestioneEconomica") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Quote associative e abbonamenti" 
                    descrizione={["Inserisci l'incasso di quote associative e abbonamenti",
                        "Controlla la situazione debitoria dell'iscritto",
                        "Genera una ricevuta pdf da dare al socio o all'abbonato (che può portarla al proprio commercialista), con eventuale precisazione di chi ha effettuato il pagamento se l'iscritto è minore",
                        "Genera una fattura elettronica verso i soci e gli abbonati"]}
                    tipoContenuto="mp4"
                    urlContenuto={props.urlMultimedia+"GestioneEconomicaQuote.mp4"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Spese" 
                    descrizione={["Inserisci le spese, anche rateali"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"GestioneEconomicaSpese.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Incassi da sponsor, enti esterni" 
                    descrizione={["Inserisci gli incassi da enti esterni (sponsor) e genera una ricevuta pdf, utile anche per generare i CUD"]}
                    tipoContenuto="mp4"
                    urlContenuto={props.urlMultimedia+"GestioneEconomicaIncassiEsterni.mp4"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Rimborsi e incassi da iscritti" 
                    descrizione={["Inserisci i rimborsi dati agli iscritti o incassi diversi ricevuti da iscritti"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"GestioneEconomicaIncassiIscritti.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Prima Nota" 
                    descrizione={["Ottieni l'elenco di tutti i movimenti in entrata e in uscita dell'anno, nella Prima Nota che viene automaticamente compilata quando inserisci incassi, spese, rimborsi, ecc.","Aggiungi direttamente voci in Prima Nota, se non si riconducono ai casi previsti"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"GestioneEconomicaPrimaNota.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Risorse e voci di bilancio" 
                    descrizione={["Per ogni entrata ed ogni uscita, definisci la risorsa (cassa, conto bancario,...) e la voce di bilancio (acquisti, affitto, quote soci, quote abbonamento,...) che comparirà nel report di bilancio"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"GestioneEconomicaRisorseVociBilancio.png"}
                    dimensioniApp={dimensioniApp}
                />
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="scadenze") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Scadenze nella Home" 
                    descrizione={["Controlla le scadenze di tutta la società semplicemente dalla Home Page dell'applicazione: documenti in scadenza, pagamenti da effettuare, qualunque adempimento che puoi inserire in uno scadenziario"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ScadenzeHome.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Scadenze nella App" 
                    descrizione={["L'iscritto ha sempre indicazione delle proprie scadenze di documenti e pagamenti e ha il dettaglio dei movimenti: importi da pagare e importi pagati"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ScadenzeApp.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Situazione Dettagliata Iscritto" 
                    descrizione={["Ottieni l'elenco degli insolventi e la situazione dettagliata di un singolo iscritto, che sia socio o che sia abbonato"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ScadenzeDettaglioInsolvente.png"}
                    dimensioniApp={dimensioniApp}
                />     
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="news") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Bacheca News" 
                    descrizione={["Inserisci, da web o da app, una comunicazione nella bacheca della società. Tutti gli iscritti riceveranno una notifica sul loro telefono e, attraverso la app, potranno leggere l'avviso"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"news.png"}
                    dimensioniApp={dimensioniApp}
                />     
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="report") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <div style={{lineHeight:2, marginTop:20, marginBottom:60, fontStyle:"italic"}}>
                    Visualizza i report nell'interfaccia web
                    <br/>
                    ed esportali in PDF per inviarli o stamparli,
                    <br/>
                    per tenere tutto sotto controllo
                </div>

                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Report Prenotazioni" 
                    descrizione={["Elenco delle prenotazioni, filtrando per corso o attrezzatura e/o per l'iscritto che ha prenotato.","Report in formato tabellare (esportabile in PDF) o grafico."]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportPrenotazioni.png"}
                    dimensioniApp={dimensioniApp}
                />     
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Report Insolventi Quote" 
                    descrizione={["Elenco dei soci e degli abbonati che hanno quote da pagare"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportInsolventi.png"}
                    dimensioniApp={dimensioniApp}
                />     
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Report Bilancio" 
                    descrizione={["Report di bilancio con le sezioni:","- Liquidità, crediti e debiti a inizio anno","- Entrate e uscite raggruppate per voce di bilancio","- Liquidità, crediti e debiti a fine anno","- Elenco movimenti","- Elenco soci.","Scegli tu quali sezioni inserire nel report.","Per ogni sezione, scegli se vuoi tabelle e/o grafici e il tipo di grafico (barre, torta,...).","Scegli quali importi visualizzare (lordi, netti, iva).","Scegli se calcolare sull'anno solare o sull'anno di esercizio (se non coincidono).","Il sistema ricorda le impostazioni per i report seguenti."]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportBilancio.png"}
                    dimensioniApp={dimensioniApp}
                />     
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Scadenze certificati medici" 
                    descrizione={["Iscritti con certificato medico scaduto o in scadenza"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportScadenzeCertificati.png"}
                    dimensioniApp={dimensioniApp}
                />     
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Scadenze documenti" 
                    descrizione={["Iscritti con documento di identità scaduto o in scadenza"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportScadenzeDocumenti.png"}
                    dimensioniApp={dimensioniApp}
                />
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Scadenze tesseramenti" 
                    descrizione={["Tesseramenti presso Federazioni o altri enti, in scadenza"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportScadenzeTesseramenti2.png"}
                    dimensioniApp={dimensioniApp}
                /> 
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Scadenze vincoli" 
                    descrizione={["Vincoli sportivi in scadenza"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportScadenzeVincoli.png"}
                    dimensioniApp={dimensioniApp}
                /> 
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Situazione economica iscritti" 
                    descrizione={["Incassi e rimborsi degli iscritti"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportSituazioneEconomicaIscritti.png"}
                    dimensioniApp={dimensioniApp}
                /> 
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Situazione economica esterni" 
                    descrizione={["Situazione delle spese effettuate dai fornitori e degli incassi ricevuti dagli enti esterni"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportSituazioneEconomicaEsterni.png"}
                    dimensioniApp={dimensioniApp}
                /> 
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Report sponsor" 
                    descrizione={["Sponsorizzazioni ricevute"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportSponsor.png"}
                    dimensioniApp={dimensioniApp}
                /> 
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Report rimborsi" 
                    descrizione={["Rimborsi erogati"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportRimborsi.png"}
                    dimensioniApp={dimensioniApp}
                /> 
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Report eventi" 
                    descrizione={["Presenze o Risultati, aggregati per gruppo (es. under16, under18,...) e/o tipo di evento oppure per singolo atleta.","Questi report sono disponibili anche nella App."]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportEventi2.png"}
                    dimensioniApp={dimensioniApp}
                /> 
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Report elenco articoli" 
                    descrizione={["Elenco articoli del merchandizing, con indicazione delle giacenze e della disponibilità alla vendita"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"ReportArticoli.png"}
                    dimensioniApp={dimensioniApp}
                /> 
                     
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }    

    if (props.pagina==="anagrafiche") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Tabelle" 
                    descrizione={["Gestisci tutte le anagrafiche ti servono:","- Iscritti (soci e abbonati),","- Direttivo,","- Libro Soci,","- Enti esterni e fornitori,","- Contatti utili e società analoghe,","- Tesseramenti,", "- Federazioni,","sempre con possibilità di ricerca e ordinamento su più colonne"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"anagrafiche.png"}
                    dimensioniApp={dimensioniApp}
                />   
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Inserisci automaticamente i dati" 
                    descrizione={["Hai già i dati in formato informatico?","Attraverso un potente importatore, puoi inserirli automaticamente in Gestione Sportiva!","L'importatore può leggere abbonamenti, abbonati, adempimenti, articoli, contatti, fornitori, esercizi, soci, movimenti economici, corsi, attrezzature, raggruppamenti, risorse, sponsor, tesseramenti, voci di bilancio e altro ancora.","Pensi sia complicato? Contattaci! Possiamo aiutarti a fare l'inserimento massivo dei dati."]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"AnagraficheImportatore.png"}
                    dimensioniApp={dimensioniApp}
                />       
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="qrcode") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Controllo Accessi" 
                    descrizione={["Gestione Sportiva si integra con i sistemi per gestire gli accessi alla tua struttura.",
                        "Gli accessi possono avvenire usando tessere (RFID o con QRCode) o la APP (QRCode).",
                        "Se hai già tornelli e lettori di schede, potrai mantenerli e farli funzionare con Gestione Sportiva.",
                        "Il sistema è totalmente personalizzabile: potrai decidere chi può accedere (i soci, gli abbonati con abbonamento valido per la struttura o per uno specifico corso), quando può accedere (orari di apertura), con quali modalità (scheda o APP).",
                        "Avrai un report in tempo reale degli accessi di ciascun iscritto."
                    ]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"Tessere.png"}
                    dimensioniApp={dimensioniApp}
                />
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="news") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Bacheca News" 
                    descrizione={["Inserisci, da web o da app, una comunicazione nella bacheca della società. Tutti gli iscritti riceveranno una notifica sul loro telefono e, attraverso la app, potranno leggere l'avviso"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"news.png"}
                    dimensioniApp={dimensioniApp}
                />     
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="sicurezza") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Sicurezza, semplicità e assistenza" 
                    descrizione={["Puoi usarlo da dove vuoi, con un semplice browser da qualunque computer, sempre con la versione aggiornata.","E iscritti ed allenatori accedono facilmente con una APP per iOS e Android.","Tutto in sicurezza, con connessioni criptate, su server gestiti da primari fornitori in Europa.", "Non sei mai da solo: per qualunque suggerimento su come utilizzare il programma, saremo sempre a tua disposizione."]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"cloudGS.png"}
                    dimensioniApp={dimensioniApp}
                />     
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="estrazione") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Importazione dei dati" 
                    descrizione={["Hai già i dati in formato elettronico? Gestione Sportiva può importarli automaticamente e noi possiamo darti supporto per farlo.","Hai i dati in un altro gestionale ma non sai come estrarli? Possiamo aiutarti anche in questo caso!"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"importazione.png"}
                    dimensioniApp={dimensioniApp}
                />     
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Estrazione dei dati" 
                    descrizione={["I dati sono tuoi e puoi scaricarli in ogni momento nel tuo PC.","Le pagine che visualizzano le tabelle permettono di scaricare i dati in formato xlsx.","Ci auguriamo che non vorrai più lasciarci, ma se lo vorrai fare potrai scaricare i tuoi dati in vari fogli elettronici, per poi usarli altrove: non dare per scontato che questo avvenga con tutti i software di questo genere."]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"estrazione.png"}
                    dimensioniApp={dimensioniApp}
                />     
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="personalizzazioni") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <ContenitorePaginaSecondaria 
                    altezza={altezzaContenitoreHome} 
                    titolo="Personalizzazioni" 
                    descrizione={["Gestione Sportiva è un prodotto che si può adattare alle tue esigenze, che sono diverse da quelle degli altri.","Aggiungere una ricerca, aggiungere un report, modificare una funzionalità, cambiare l'aspetto grafico:","contattaci e valuteremo insieme come personalizzare la TUA Gestione Sportiva"]}
                    tipoContenuto="png"
                    urlContenuto={props.urlMultimedia+"personalizzazioni.png"}
                    dimensioniApp={dimensioniApp}
                />     
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="contatti") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                Per informazioni commerciali scrivi a: <a href="mailto:info@gestionesportiva.it">info@gestionesportiva.it</a>
                <br/>
                <br/>
                Per richieste di assistenza scrivi a: <a href="assistenza@gestionesportiva.it">assistenza@gestionesportiva.it</a>
                <br/>
                <br/>
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="privacy") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                Questo sito non utilizza cookies di tracciamento o di profilazione dei visitatori.<br/>
                Le pagine che chiedono dati personali, come l'indirizzo email, richiedono che l'utente esprima (nella stessa pagina) un esplicito consenso per il trattamento e la memorizzazione di questi dati, che vengono utilizzati unicamente per fornire il servizio richiesto (ad esempio la prova gratuita dell'applicazione o l'acquisto) e non vengono in ogni caso ceduti a terzi.<br/>
                Per maggiori dettagli, consultare i seguenti documenti:
                <br/>
                <br/>
                <a href="https://gestionesportiva.it/documenti/AIG_GDPR.pdf">Documento trattamento dati</a>                
                <br/>
                <br/>
                <a href="https://gestionesportiva.it/documenti/AIG_GS_TERMCOND.pdf">Documento Termini e Condizioni</a>
                <br/>
                <br/>
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="condizioni") {
        return (
            <div className='App'>     
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>                
                Il seguente documento descrive i Termini e le Condizioni per l'utilizzo di Gestione Sportiva:
                <br/>
                <br/>
                <a href="https://gestionesportiva.it/documenti/AIG_GS_TERMCOND.pdf">Documento Termini e Condizioni</a>
                <br/>
                <br/>
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )        
    }

    if (props.pagina==="provaGratis") {
        return (<ProvaGratis {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>)                
    }

    if (props.pagina==="acquista") {
        return (<Acquista {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>)                
    }

    if (props.pagina==="prezzi") {
        return (<Prezzi {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>)                
    }

    if (props.pagina==="scaricaApp") {
        return (<ScaricaApp {...props}  produttore={produttore} dimensioniApp={dimensioniApp}/>)
    }

    if (props.pagina==="chiSiamo") {
        return (<ChiSiamo {...props}  produttore={produttore} dimensioniApp={dimensioniApp}/>)
    }

    if (props.pagina!=="home") {
        // pagina ancora da fare, visualizzo solo il layout e il titolo
        return (
            <div  className='App'>
                <MenuAlto {...props} bottoneHome={true} dimensioniApp={dimensioniApp}/>
                <Intestazione {...props} dimensioniApp={dimensioniApp}/>
                <TitoloPagina {...props}/>
                <FondoPagina {...props} produttore={produttore} dimensioniApp={dimensioniApp}/>
            </div>
        )
    }    

    return (
        <div style={{textAlign: "center", color:"#505050"}}>   
            
            <MenuAlto {...props} bottoneHome={false} dimensioniApp={dimensioniApp}/>
            
            <Intestazione {...props} dimensioniApp={dimensioniApp}/>
            <div style={{paddingTop:20, paddingBottom:20, color:"#909090", backgroundColor:"#F5F5F5", marginLeft:20, marginRight:20}}>      
                <video style={{height:300, maxWidth:dimensioniApp.x-40}} autoPlay loop muted webkit-playsinline="true" playsinline="true">
                <source src={props.urlMultimedia+"gestionesportivachat.mp4"} type="video/mp4"/>
                Il browser non supporta la riproduzione di video.
                </video>      

                <div style={{display:"flex", flexWrap: "wrap", justifyContent: "center", paddingTop:20}}>  
                    <div style={{color:"#FF4040", cursor:"pointer", paddingLeft:20, paddingRight:20}} onClick={() => vaiLink("/provaGratis")} ><b>Prova gratis</b> →</div>
                    <div style={{color:"#4040FF", cursor:"pointer", whiteSpace: "nowrap", paddingLeft:20, paddingRight:20}} onClick={() => vaiLink("/scaricaApp")} >APP Iscritti →</div>
                    <div style={{color:"#4040FF", cursor:"pointer", paddingLeft:20, paddingRight:20}} onClick={() => vaiLink("/acquista")}>Acquista →</div>
                    <div style={{color:"#4040FF", cursor:"pointer", paddingLeft:20, paddingRight:20}}  onClick={() => window.location.href = props.urlServizio}>Accedi →</div>                    
                </div>
            </div>            

            <div style={{lineHeight:2, marginTop:40, marginBottom:60}}>
                <span style={{fontSize:20, fontWeight:'bold'}}>Cos'è Gestione Sportiva?</span>
                <div style={{height:40}}></div>
                E' un <span style={{fontSize:20, fontWeight:'bold'}}>servizio Web</span> e anche una <span style={{fontSize:20, fontWeight:'bold'}}>APP</span>,
                <br/>
                <div style={{display:'flex', justifyContent:'center'}}>
                <div>sviluppato da</div>
                <a href='https://aigenova.it'><img style={{marginLeft:5, marginTop:-1}} src={logoAIG} height={40} alt="Azienda Informatica Genova"/></a>,
                </div>

                <div style={{justifyContent:'center', marginTop:-12}}>
                    consente alle <span style={{fontSize:20, fontWeight:'bold'}}>associazioni, società sportive e palestre</span> e agli <span style={{fontSize:20, fontWeight:'bold'}}>atleti</span>
                    <br/>
                    di gestire eventi e convocazioni, prenotazioni, schede allenamenti, spese, incassi, bilancio, scadenze, comunicazioni...<br/>
                    ... ed è <span style={{fontSize:20, fontWeight:'bold'}}>personalizzabile</span> sulle <span style={{fontSize:20, fontWeight:'bold'}}>tue</span> esigenze.
                </div>
            </div>            

            <ContenitoreHome
                titolo="Eventi" 
                descrizione={["Crea eventi per allenamenti, gare, manifestazioni,...","Invia le convocazioni con un click","Usa chat ordinate e distinte per evento, tra lo staff e i convocati","Ottieni report delle presenze e dei risultati"]}
                immagine="imgEventi"
                callback={() => vaiLink("/eventi")}
                posizioneImmagine="sinistra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome 
                titolo="Prenotazioni" 
                descrizione={["Prenota corsi, attività, attrezzature"]}
                immagine="imgPrenotazioni"
                callback={(y) => vaiLink("/prenotazioni")}
                posizioneImmagine="destra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome
                titolo="Allenamenti" 
                descrizione={["Crea schede allenamenti predefinite e personalizzate, visualizzale su app e genera PDF che potrai inviare o stampare"]}
                immagine="imgAllenamenti"
                callback={() => vaiLink("/allenamenti")}
                posizioneImmagine="sinistra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome
                titolo="Gestione economica" 
                descrizione={["Gestisci quote associative, abbonamenti, spese, rimborsi, sponsor, prima nota, fatture elettroniche..."]}
                immagine="imgGestioneEconomica"
                callback={() => vaiLink("/gestioneEconomica")}
                posizioneImmagine="destra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome
                titolo="Avvisi e scadenze" 
                descrizione={["Tieni sotto controllo le scadenze di pagamenti, quote di iscrizione, certificati medici, documenti di identità, adempimenti"]}
                immagine="imgScadenze"
                callback={() => vaiLink("/scadenze")}
                posizioneImmagine="sinistra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome
                titolo="Comunicazioni" 
                descrizione={["Invia a tutti comunicazioni e avvisi, direttamente sulle app degli iscritti"]}
                immagine="imgNews"
                callback={() => vaiLink("/news")}
                posizioneImmagine="destra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome
                titolo="Report" 
                descrizione={["Genera tanti report con tabelle e grafici per tenere tutto sotto controllo: bilancio, insolventi, prenotazioni, risultati sportivi, tesseramenti"]}
                immagine="imgReport"
                callback={() => vaiLink("/report")}
                posizioneImmagine="sinistra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome
                titolo="Anagrafiche" 
                descrizione={["Gestisci soci, atleti, direttivo, fornitori, federazioni, tesseramenti, enti esterni, società analoghe, contatti utili.","Gestisci i rapporti con genitori o altri riferimenti degli iscritti.","Se hai già questi dati in formato informatico, puoi inserirli automaticamente con una importazione di dati"]}
                immagine="imgAnagrafiche"
                callback={() => vaiLink("/anagrafiche")}
                posizioneImmagine="destra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome
                titolo="Controllo Accessi" 
                descrizione={["Gestisci l'accesso ai tornelli con lettori di schede RFID o QRCode che si interfacciano con Gestione Sportiva"]}
                immagine="imgControlloAccessi"
                callback={() => vaiLink("/qrcode")}
                posizioneImmagine="sinistra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome
                titolo="Estrazione e importazione dei dati" 
                descrizione={["I dati sono tuoi e puoi scaricarli in ogni momento.","Puoi scaricare i dati in formato xlsx direttamente dalla pagina che visualizza ogni tabella.","Puoi importare i dati da un altro gestionale o da altri formati elettronici, con eventuale nostro supporto"]}
                immagine="imgImportExport"
                callback={() => vaiLink("/estrazione")}
                posizioneImmagine="destra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome
                titolo="Sicurezza, semplicità e assistenza" 
                descrizione={["Non devi installare nulla: ti basta un collegamento a Internet, puoi usare qualunque PC o tablet per accedere.","Non sei mai da solo: per qualunque suggerimento su come utilizzare il programma, saremo sempre al tuo fianco"]}
                callback={() => vaiLink("/sicurezza")}
                posizioneImmagine="destra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome
                titolo="Personalizzazioni" 
                descrizione={["Le società e le associazioni sono tutte diverse, ognuno di noi utilizza i programmi in modo diverso.","Per questo, Gestione Sportiva si adatta a te!","Contattaci e potremo personalizzare GestioneSportiva sulle tue esigenze"]}
                callback={() => vaiLink("/personalizzazioni")}
                posizioneImmagine="sinistra"
                dimensioniApp={dimensioniApp}
            />
            <ContenitoreHome
                titolo="Prezzi" 
                descrizione={["Quanto costa? Clicca qui per il listino.","Potrai pagare con PayPal, carta di credito o bonifico bancario"]}
                callback={() => vaiLink("/prezzi")}
                posizioneImmagine="sinistra"
                dimensioniApp={dimensioniApp}
            />
            <FondoPagina {...props} produttore={produttore} pagina="principale" dimensioniApp={dimensioniApp}/>
        </div>
    );
}

export default PaginaPrincipale;